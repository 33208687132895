<template>
    <layout-login>
        <v-container>
            <v-card width="400" class="mx-auto mt-5">
                <v-card-title class="pb-3 text-h4">
                    Login
                </v-card-title>
                <v-card-text>
                    <v-form>
                    <v-text-field 
                        label="Email" 
                        prepend-icon="mdi-account-circle"
                        v-model="user.email"
                    />
                    <v-text-field 
                        :type="showPassword ? 'text' : 'password'" 
                        label="Password"
                        prepend-icon="mdi-lock"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        v-model="user.password"
                    />
                    </v-form>
                    <div class="red--text text-center">{{errorMsg}}</div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" @click="login()">Login</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </layout-login>
</template>
<script>
export default {
    data:() => ({
        showPassword: false,
        user: {},
        errorMsg: '',
    }),
    methods: {
        login() {
            // if (this.$route.query.redirect)
            // console.log(this.$route.query.redirect)
            // else console.log('undd')
            this.$store.dispatch('login', this.user).then(response=> {
                console.log('logged in', response)
                if (!response.status) return this.errorMsg = 'Login failed!'
                if (response.profile.type=='vendor') return this.$router.push('/vendordashboard')
                if (this.$route.query.redirect) this.$router.push(this.$route.query.redirect)
                else this.$router.push('/')
            })
        }
    }
}
</script>